export const zhCN = {
  'We provide a demo for you to try out the features. You can start a one-to-one chat or group chat. Let us chat!': '我们提供了一个 demo 供您试用这些功能。您可以开始一对一聊天或群聊。我们来聊聊吧！',
  'Please note that by using this demo, we will process your personal data in accordance with our Tencent Cloud': '请注意，通过使用此 demo，我们将处理您的个人数据根据腾讯云',
  'Privacy Policy': '隐私政策',
  'Welcome to': '欢迎',
  'chats': '消息',
  'contacts': '通讯录',
  'Recommended Demo Features': '建议体验功能',
  'Send a message': '发送一条消息',
  'Recall a message': '撤回一条消息',
  'Start a Chat': '发起一个会话',
  'Delete a chat': '删除一个会话',
  'Create a group chat': '发起一个群聊',
  'Get Started - Run a Demo': '快速跑通 Demo',
  'Completed': '已完成',
  'Not tried yet': '待体验',
  'Tencent Cloud': '腾讯云',
  'Chat': '即时通讯 IM',
  'Free Trial': '免费体验',
  'Get Started': '开始',
  'Developer Group': '开发者群',
  'Join': '加入',
  'Details': '详情',
  'Follow': '关注',
  'Subscribe': '订阅',
  'Give a like': '喜欢',
  'Give a reward': '打赏',
  'See the participants': '查看参与者',
  'Ask a question': '问一次问题',
  'Feedback to a reply': '反馈一次回复'
};
